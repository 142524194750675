<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Informasi Pribadi
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update Informasi Pribadi</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Simpan
        </button>
        <button
          type="reset"
          class="btn btn-secondary"
          @click="
            cancel();
            $router;
          "
        >
          Kembali
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"> </label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Informasi Biodata</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Nama Lengkap</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Nama Lengkap"
              v-model="user.name"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">NIP</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="nip"
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="NIP"
              v-model="user.username"
              readonly
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">
            Tempat Lahir
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="tempat_lahir"
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Tempat Lahir"
              v-model="user.tempat_lahir"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">
            Jenis Kelamin
          </label>
          <div class="col-lg-9 col-xl-6">
            <s-select
              :options="jenisKelamin"
              label="text"
              v-model="user.jenis_kelamin"
              :reduce="(data) => data.value"
              :placeholder="user.jenis_kelamin"
              class="custom-height"
            ></s-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">
            Tanggal Lahir
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="tanggal_lahir"
              class="form-control form-control-lg form-control-solid"
              type="date"
              v-model="user.tanggal_lahir"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">
            Pendidikan Terakhir
          </label>
          <div class="col-lg-9 col-xl-6">
            <s-select
              :options="pendidikan"
              label="text"
              v-model="user.pendidikan_terakhir"
              :reduce="(data) => data.value"
              :placeholder="pendTerakhir"
              class="custom-height"
            ></s-select>
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Informasi Pekerjaan</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >PTSP</label
          >
          <div class="col-lg-9 col-xl-6">
            <s-select
              :options="ptsp"
              label="text"
              v-model="selectPtsp"
              :reduce="(data) => data.value"
              :placeholder="nameptsp"
              class="custom-height"
            ></s-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Bidang Ilmu</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="bidang"
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Bidang Ilmu"
              v-model="user.bidang_ilmu"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Bagian</label
          >
          <div class="col-lg-9 col-xl-6">
            <s-select
              :options="bagian"
              label="text"
              v-model="user.bagian"
              :reduce="(data) => data.value"
              :placeholder="getbagian"
              class="custom-height"
              @input="handleBagianChange"
            ></s-select>
            <br />
            <input
              v-if="showOtherInput"
              type="text"
              class="form-control form-control-lg form-control-solid"
              placeholder="Bagian Lainnya"
              v-model="user.bagian_lainnya"
            />
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Informasi Kontak</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Nomor Telepon</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="no_hp"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="No HP"
                v-model="user.no_hp"
                v-on:input="validateNumericInput"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Alamat Email</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-model="user.email"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Alamat</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-home"></i>
                </span>
              </div>
              <input
                ref="alamat"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Alamat"
                v-model="user.alamat"
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import axios from "axios";
import VueSelect from "vue-select";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import Swal from "sweetalert2";

export default {
  name: "PersonalInformation",
  components: {
    "s-select": VueSelect,
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      showOtherInput: false,
      getbagian: "",
      pendTerakhir: "",

      // postData
      // name: "",
      // tempat_lahir: "",
      // tanggal_lahir: "",

      selectPendidikan: null,
      pendidikan: [
        { value: null, text: "Silahkan Pilih" },
        { value: "SD/Sederajat", text: "SD/Sederajat" },
        { value: "SMP/Sederajat", text: "SMP/Sederajat" },
        { value: "SMA/Sederajat", text: "SMA/Sederajat" },
        { value: "D1", text: "D1" },
        { value: "D2", text: "D2" },
        { value: "D3", text: "D3" },
        { value: "D4", text: "D4" },
        { value: "S1", text: "S1" },
        { value: "S2", text: "S2" },
        { value: "S3", text: "S3" },
      ],

      jenisKelamin: [
        { value: "lakilaki", text: "Laki-Laki" },
        { value: "perempuan", text: "Perempuan" },
      ],

      nameptsp: "",
      selectPtsp: null,
      ptsp: [],

      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        {
          value: "kabidpb",
          text: "Kepala Bidang Perizinan Berusaha/Pejabat Fungsional Madya Bidang Perizinan Berusaha",
        },
        {
          value: "kasiepb",
          text: "Kepala Seksi Bidang Perizinan Berusaha/Pejabat Fungsional Muda Bidang Perizinan Berusaha",
        },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        {
          value: "boffpb",
          text: "Staf Back-office Bidang Perizinan Berusaha",
        },
        {
          value: "hdeskpb",
          text: "Staf Help Desk Bidang Perizinan Berusaha",
        },
        {
          value: "kabiddal",
          text: "Kepala Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Madya Bidang Pengendalian Pelaksanaan",
        },
        {
          value: "kasiedal",
          text: "Kepala Seksi Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Muda Bidang Pengendalian Pelaksanaan",
        },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
        { value: "lainnya", text: "Lainnya" },
      ],
    };
  },
  methods: {
    validateNumericInput() {
      this.user.no_hp = this.user.no_hp.replace(/[^0-9]/g, "");
    },
    handleBagianChange(value) {
      this.showOtherInput = value === "lainnya";
    },
    async save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // Create a new object to hold only the properties you want to send
      const requestData = {
        name: this.user.name,
        // username: this.user.username,
        tempat_lahir: this.user.tempat_lahir,
        tanggal_lahir: this.user.tanggal_lahir,
        jenis_kelamin: this.user.jenis_kelamin,
        pendidikan_terakhir: this.user.pendidikan_terakhir,
        id_ptsp: this.selectPtsp !== null ? this.selectPtsp : this.user.id_ptsp,
        bidang_ilmu: this.user.bidang_ilmu,
        bagian:
          this.user.bagian === "lainnya"
            ? this.user.bagian_lainnya
            : this.user.bagian,
        no_hp: this.user.no_hp,
        // email: this.user.email,
        alamat: this.user.alamat,
      };

      // send update request
      try {
        const response = await axios.put(
          this.url + "/users/" + this.user.id,
          requestData, // Send the filtered data object
          {
            headers: {
              xth: this.token,
            },
          }
        );

        if (response.status === 200) {
          this.$store.dispatch(UPDATE_PERSONAL_INFO, requestData);
          Swal.fire("Sukses!", "Data Berhasil Diubah", "success").then(() => {
            this.getData();
            this.cancel();
          });
        }
      } catch (error) {
        console.error("Error occurred while saving:", error);
        Swal.fire("Error", "Data Gagal Diubah.", "error"); // Display error message
      }

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    //masih belom bisa setItem :(
    async getData() {
      try {
        const response = await axios.get(this.url + "/users/" + this.user.id, {
          headers: {
            xth: this.token,
          },
        });

        if (response.status === 200) {
          const userData = response.data.data;
          localStorage.setItem("user", JSON.stringify(userData));
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    },
    async getptsp() {
      const response = await axios.get(this.url + "/profil?sort=idptsp%20ASC", {
        headers: {
          xth: this.token,
        },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    },
    async getNamePtsp() {
      axios
        .get(this.url + "/profil?filter=idptsp,=," + this.user.id_ptsp)
        .then((response) => {
          this.nameptsp = response.data.data[0].daerah;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getPendidikanTerakhir() {
      axios
        .get(this.url + "/users/" + this.user.id_ptsp)
        .then((response) => {
          this.pendTerakhir = response.data.data[0].pendidikan_terakhir;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getBagian() {
      axios
        .get(this.url + "/users/" + this.user.id_ptsp)
        .then((response) => {
          this.getbagian = response.data.data[0].bagian;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancel() {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.$router.push("/");
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  async mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
    const profileData = await this.getptsp();
    this.ptsp = profileData.map((item) => ({
      text: item.daerah,
      value: item.idptsp,
    }));
    this.getNamePtsp();
    this.getPendidikanTerakhir();
    this.getData();
  },
};
</script>
<style scoped>
::v-deep .custom-height .vs__dropdown-toggle {
  background-color: #f3f6f9;
  border-color: #ffffff;
  padding-left: 10px;
  padding-right: 15px;
}
::v-deep .custom-height .vs__dropdown-toggle:active,
::v-deep .custom-height .vs__dropdown-toggle:focus {
  background-color: #ebedf3;
  border-color: #ffffff;
  padding-left: 10px;
  padding-right: 15px;
}
::v-deep .custom-height .vs__selected-options {
  height: auto;
  margin-top: 7px;
  margin-bottom: 5px;
}
</style>