<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Ubah Kata Sandi</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Ubah Password Akun</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Simpan
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Kembali
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Kata Sandi Sekarang</label
          >
          <div class="col-lg-9 col-xl-6">
            <v-text-field
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showCurrentPassword ? 'text' : 'password'"
              name="input-10-2"
              hint=""
              value=""
              class="form-control form-control-lg form-control-solid p-2"
              @click:append="showCurrentPassword = !showCurrentPassword"
              v-model="passwordLama"
            ></v-text-field>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Kata Sandi Baru</label
          >
          <div class="col-lg-9 col-xl-6">
            <v-text-field
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              name="input-10-2"
              hint="Setidaknya menggunakan satu huruf besar dan angka"
              value=""
              class="form-control form-control-lg form-control-solid p-2"
              @click:append="showNewPassword = !showNewPassword"
              v-model="passwordBaru"
            ></v-text-field>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Konfirmasi Kata Sandi</label
          >
          <div class="col-lg-9 col-xl-6">
            <v-text-field
              :append-icon="showVerifyPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showVerifyPassword ? 'text' : 'password'"
              name="input-10-2"
              hint=""
              value=""
              class="form-control form-control-lg form-control-solid p-2"
              @click:append="showVerifyPassword = !showVerifyPassword"
              v-model="confirmPassword"
            ></v-text-field>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  data() {
    return {
      passwordLama: "",
      passwordBaru: "",
      confirmPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showVerifyPassword: false,
      user: JSON.parse(localStorage.getItem("user")),
      token: localStorage.getItem("id_token"),
      url: localStorage.getItem("baseapi"),
      idprofil: localStorage.getItem("idprofil"),
    };
  },
  methods: {
    save() {
      // check if old password is correct
      if (this.passwordLama != this.user.password_plain) {
        this.$bvToast.toast(
          "Password lama tidak sama, silahkan ulang kembali",
          {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          }
        );
      } else {
        // check if new password and confirm password are the same
        if (this.passwordBaru != this.confirmPassword) {
          this.$bvToast.toast(
            "Password baru tidak sama, silahkan diulang kembali",
            {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            }
          );
        } else {
          // send update request
          axios
            .put(
              this.url + "/users/" + this.user.id,
              {
                _method: "patch",
                password: this.passwordBaru,
              },
              {
                headers: {
                  xth: this.token,
                },
              }
            )
            .then((res) => {
              Swal.fire("Success", "Password Berhasil Diubah", "success");
              this.getData();
              console.log(res.data);
              this.cancel();
              return res;
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Failed", "Password Gagal Diubah", "error");
              this.cancel();
              return err;
            });
        }
      }
    },
    cancel() {
      this.passwordLama = "";
      this.passwordBaru = "";
      this.confirmPassword = "";
      this.$router.push("/");
    },
    async getData() {
      try {
        const response = await axios.get(this.url + "/detail", {
          headers: {
            xth: this.token,
          },
        });

        if (response.status === 200) {
          const userData = response.data.data; // Get the user data from the response

          // Update localStorage with the received user data
          localStorage.setItem("user", JSON.stringify(userData));

          // Update the local user data object
          this.user = userData;
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    },
  },
};
</script>
